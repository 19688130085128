import styled from '@emotion/styled'
import { calcRem, breakpoint } from '@news-mono/component-library'
import { metricsPN } from '@news-mono/design-tokens'

const leftColumnStart = 1
const leftColumnSize = 2

const mainColumnStart = leftColumnStart + leftColumnSize
const mainColumnSize = 3

const rightColumnStart = mainColumnStart + mainColumnSize
const rightColumnSize = 2

const columnTotalTablet = 5
const rightColumnStartTablet = 1
const rightColumnSizeTablet = columnTotalTablet

const columnTotal = leftColumnSize + mainColumnSize + rightColumnSize

export const StyledTritonWrapper = styled('section')<{
    isTopSection?: boolean
}>(({ isTopSection = false }) => ({
    marginTop: calcRem(metricsPN.layout.section.mobile),
    marginBottom: calcRem(metricsPN.layout.section.mobile),
    [breakpoint('sm')]: {
        marginTop: isTopSection
            ? calcRem(metricsPN.spacing['L'])
            : calcRem(metricsPN.layout.section.tablet),
        marginBottom: calcRem(metricsPN.layout.section.tablet),
    },

    [breakpoint('lg')]: {
        marginTop: isTopSection
            ? calcRem(metricsPN.spacing['L'])
            : calcRem(metricsPN.layout.section.desktop),
        marginBottom: calcRem(metricsPN.layout.section.desktop),
    },
}))

export const StyledPNTritonBody = styled('div')(() => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '100%',
    gridGap: calcRem(metricsPN.spacing['L']),

    [breakpoint('sm')]: {
        gridTemplateColumns: `repeat(${columnTotalTablet}, 1fr)`,
    },

    [breakpoint('lg')]: {
        gridTemplateColumns: `repeat(${columnTotal}, 1fr)`,
    },
}))

export const StyledLeftSideBar = styled('div')(() => ({
    [breakpoint('sm')]: {
        gridColumnStart: leftColumnStart,
        gridColumnEnd: leftColumnStart + leftColumnSize,
    },
}))

export const StyledPNTritonMain = styled('div')<{ isTopSection?: boolean }>(
    ({ isTopSection }) => ({
        // Middle column in top section will be placed on top of left column
        gridRow: isTopSection ? 1 : 2,
        [breakpoint('sm')]: {
            gridRow: 1,
            gridColumnStart: mainColumnStart,
            gridColumnEnd: mainColumnStart + mainColumnSize,
        },
        [breakpoint('lg')]: {
            gridRow: 1,
        },
    }),
)

export const StyledRightSideBar = styled('div')(() => ({
    [breakpoint('sm')]: {
        gridRowStart: 2,
        gridColumnStart: rightColumnStartTablet,
        gridColumnEnd: rightColumnStartTablet + rightColumnSizeTablet,
    },
    [breakpoint('lg')]: {
        gridRowStart: 1,
        gridColumnStart: rightColumnStart,
        gridColumnEnd: rightColumnStart + rightColumnSize,
    },
}))
