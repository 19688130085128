import styled from '@emotion/styled'
import { calcRem } from '@news-mono/component-library'
import { Skeleton } from '../../../content/Skeleton/Skeleton'
import { colorsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../mixins/TextMixin/TextMixin.theme'

export const StyledActionLinks = styled('div')(() => [
    {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        flexShrink: 0,
        position: 'relative',
    },
])

export const StyledAction = styled('span')(() => [
    {
        ...perthNowNGNTextMixin.label.L.semibold,
        color: colorsPN.text.primary,
        display: 'inline-block',
        background: 'none',
        border: 'none',
        textTransform: 'uppercase',
        textDecoration: 'none',
        padding: calcRem(8, 8, 8, 0),
        flexGrow: 0,
        textAlign: 'center',
        outline: 'none',
        '&:not(:last-child):after': {
            display: 'inline-block',
            content: `'|'`,
            margin: calcRem(0, 0, 0, 8),
            color: colorsPN.text.primary,
        },
    },
])

export const StyledActionLink = styled(StyledAction)({
    cursor: 'pointer',
    '&:focus-visible': {
        textDecoration: 'underline',
    },
    '&:hover': {
        color: colorsPN.text.brand,
    },
    '&:active': {
        color: colorsPN.text.pressed,
    },
}).withComponent('button')

export const StyledSkeleton = styled(Skeleton)({
    display: 'inline-block',
    width: calcRem(60),
    backgroundColor: colorsPN.background.base,
    margin: calcRem(8, 0, 8, 16),
})
