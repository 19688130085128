import { colorsPN, metricsPN } from '@news-mono/design-tokens'

type Theme = {
    button: {
        text: {
            color: string
        }
        fill: string
        hover: string
        pressed: string
        border: {
            color: string | undefined
            radius: number
        }
    }
    background: string
    border: {
        color: string | undefined
        radius: number
    }
    text: {
        color: string
    }
    banner?: boolean
}

export type PNThemeCardTheme = 'streamer-screamer' | 'default'

type CardThemes = {
    [key in PNThemeCardTheme]: Theme
}

export const cardThemes: CardThemes = {
    'streamer-screamer': {
        button: {
            text: { color: colorsPN.text.inverse },
            fill: '#EF4623',
            hover: '#F27043',
            pressed: '#B4351A',
            border: {
                radius: metricsPN.radius['XL'],
                color: undefined,
            },
        },
        background: `#120015`,
        border: {
            radius: metricsPN.radius['M'],
            color: undefined,
        },
        text: {
            color: colorsPN.text.inverse,
        },
        banner: true,
    },
    default: {
        button: {
            text: { color: colorsPN.text.brand },
            fill: 'transparent',
            hover: colorsPN.fill.hover.brand,
            pressed: colorsPN.fill.pressed.brand,
            border: {
                radius: metricsPN.radius['L'],
                color: colorsPN.fill.brand.strong,
            },
        },
        background: colorsPN.background.base,
        border: {
            radius: metricsPN.radius['M'],
            color: colorsPN.stroke.weak,
        },
        text: {
            color: colorsPN.text.primary,
        },
    },
}
