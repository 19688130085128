import React from 'react'
import { StyledIconZap, StyledIconZapProps } from '../IconZap/IconZap.styled'
import { colorsPN } from '@news-mono/design-tokens'

export interface IconZapProps {
    title?: string
}

export const IconZap: React.FC<IconZapProps & StyledIconZapProps> = ({
    stroke = colorsPN.icon.brand,
    width,
    height,
}) => (
    <StyledIconZap
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledIconZap>
)
