import styled from '@emotion/styled'
import { metricsPN, colorsPN } from '@news-mono/design-tokens'
import { breakWords } from '../../../../../__styling/style-mixins'
import { breakpoint, calcRem } from '../../../../../__styling'
import { perthNowNGNTextMixin } from '../../../../../mixins'
import {
    StyledBylineAuthor,
    StyledBylineTextBlock,
    StyledBylineTextWrap,
    StyledFundingLabel,
    StyledMultipleTimestampWrapper,
} from '../../../../../publication/Byline/Byline.styled'

const { text, stroke, icon } = colorsPN
const { spacing } = metricsPN

export const StyledPNByline = styled('address')({
    breakWords,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: 0,
    borderBottom: 'none',
    width: '100%',

    [breakpoint('xs')]: {
        flexWrap: 'nowrap',
    },
})

export const StyledPNHeadshotWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginRight: calcRem(spacing['2XS']),
    img: {
        width: calcRem(spacing.S),
        height: calcRem(spacing.S),
        borderRadius: '50%',
    },
})

export const StyledPNBylineText = styled('div')({
    flexGrow: 0,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: 'auto',
    marginRight: 'auto',
})

export const StyledPNBylineTextWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'start',
    overflow: 'hidden',
    rowGap: calcRem(spacing['2XS']),

    'a, span': {
        color: text.secondary,
        textDecoration: 'none',
        ...perthNowNGNTextMixin.label.M.medium,
    },

    'a:hover': {
        background: 'none',
        span: {
            color: text.brand,
        },
    },

    time: {
        color: icon.secondary,
        marginRight: 0,
        ...perthNowNGNTextMixin.label.M.regular,

        '&:not(:last-child)': {
            borderRight: `2px solid ${stroke.weak}`,
            paddingRight: calcRem(spacing['2XS']),
        },

        '&:before': {
            display: 'none',
        },
    },

    [`${StyledBylineTextWrap}`]: {
        height: 'fit-content',
        rowGap: calcRem(spacing['2XS']),
    },

    [`${StyledBylineAuthor}`]: {
        paddingRight: calcRem(spacing['2XS']),
        marginRight: calcRem(spacing['2XS']),
        height: 'fit-content',
        borderRight: `2px solid ${stroke.weak}`,
    },

    [`${StyledBylineTextBlock}`]: {
        display: 'flex',
        alignSelf: 'center',
        height: 'fit-content',
        paddingRight: calcRem(spacing['2XS']),
        marginRight: calcRem(spacing['2XS']),
        borderRight: `2px solid ${stroke.weak}`,

        '&:before': {
            display: 'none',
        },
    },

    [`${StyledMultipleTimestampWrapper}`]: {
        gap: calcRem(spacing['2XS']),
    },

    [`${StyledFundingLabel}`]: {
        '&:before': {
            display: 'none',
        },
    },
})

export const StyledPNMetaDataWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    overflow: 'hidden',
})
