import styled from '@emotion/styled'
import { zIndex, breakpointMax } from '@news-mono/component-library'
import { colorsPN } from '@news-mono/design-tokens'

interface StyledListProps {
    isSnapshot: boolean
}

export const StyledContainer = styled('nav')({
    alignItems: 'center',
    zIndex: zIndex.perthnow.mainNavigation,
    background: colorsPN.background.base,
    width: 'fit-content',
    margin: '0 auto',
})

export const StyledList = styled('ul')<StyledListProps>(({ isSnapshot }) => ({
    listStyle: 'none',
    padding: 0,
    margin: '0 auto',
    flexDirection: 'row',
    display: 'flex',
    height: isSnapshot ? 'fit-content' : '100%',
    [breakpointMax('md')]: {
        width: '100%',
        justifyContent: 'center',
    },
}))
