import loadable from '@loadable/component'
import {
    PlayerEvent,
    VideoDetails,
    VideoQueueItemMeta,
} from '@news-mono/web-common'
import React from 'react'
import { MediaMode } from '../../../../cards/CardMedia/CardMedia'
import {
    AutoplayOptions,
    GetVideoQueue,
    VideoOptions,
} from '../../../../content/Video/PlayerInterface'
import { FeatureToggle } from '../../../../feature-toggling/Toggle/Toggle'
import { FigcaptionRenderOptions } from '../../../../publication/ArticleImage/ArticleImage'
import { createRenderTarget } from '../../../../render-target'
import { PNRssArticleVideo } from './PNArticleVideo.rss'
import { PNArticleImage } from '../PNArticleImage/PNArticleImage'
import PNAmpArticleVideo from './PNArticleVideo.amp'

export const PNLazyWebArticleVideo = loadable(
    () => import('./PNArticleVideo.web'),
)

export interface PNVideoArticle {
    isHeroVideo?: boolean
    isVideoStickyEnabled?: boolean
    publicationPathname?: string
}

export interface PNArticleVideoProps extends PNVideoArticle {
    showTitles: boolean
    videoDetails: VideoDetails
    videoOptions: VideoOptions
    autoplayOptions: AutoplayOptions | false
    autoplayNextOptions: AutoplayOptions | false
    pauseOverlay: boolean
    getVideoQueue: GetVideoQueue
    byline?: string
    captionText?: string
    credit?: string
    kind?: string
    figcaptionRenderOptions?: FigcaptionRenderOptions
    onEvent: (event: PlayerEvent) => void
    playButtonText?: string
    smallPlayButton?: boolean
    mediaMode?: MediaMode
    isVideoHub?: boolean
    publicUrl?: string
    onPlayNextVideo?: (nextVideo: VideoQueueItemMeta) => void
    adElement?: JSX.Element
    setVideoRendered?: () => void
}

const PNArticleVideoRenderTarget = createRenderTarget<PNArticleVideoProps>(
    'PNArticleVideo',
    {
        web: PNLazyWebArticleVideo,
        // todo: remove this once we implement amp redirect since amp is no longer needed
        amp: PNAmpArticleVideo,
        rss: PNRssArticleVideo,
        preview: PNLazyWebArticleVideo,
        app: PNLazyWebArticleVideo,
    },
)
export const PNArticleVideo = (props: PNArticleVideoProps) => {
    return (
        <FeatureToggle
            feature={'brightcove-player'}
            on={() => <PNArticleVideoRenderTarget {...props} />}
            off={() => {
                if (!props.videoDetails.posterImage) {
                    return null
                }
                return (
                    <PNArticleImage
                        image={props.videoDetails.posterImage}
                        figCaption={{
                            byline: props.byline,
                            captionText: props.captionText,
                            credit: props.credit,
                            kind: 'image',
                        }}
                        figcaptionRenderOptions={props.figcaptionRenderOptions}
                        isHeroImage={true}
                    />
                )
            }}
        />
    )
}

PNArticleVideo.displayName = 'PNArticleVideo'
