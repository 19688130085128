import React from 'react'
import { useSelector } from 'react-redux'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { AppState, useToggleState } from '@news-mono/web-common'
import SecondaryNav from './BaseComponent/SecondaryNav'
import { BasePerthNowProps } from './BasePerthNow'
import Template from './BaseComponent/Template'
import Header from './BaseComponent/Header'
import PrimaryNav from './BaseComponent/PrimaryNav'
import WindowShade from './BaseComponent/WindowShade'
import Content from './BaseComponent/Content'
import FooterAd from './BaseComponent/FooterAd'
import Footer from './BaseComponent/Footer'

export const BasePerthNowWeb: React.FC<BasePerthNowProps> = ({
    additionalPageProperties,
    ads,
    onEvent,
    section,
    pageContents,
    pageType,
    mobileNavExpanded,
    location,
    toggleMobileNav,
    version,
    services,
}) => {
    const featureState = toFeatureState(useToggleState())
    const isAltFooter = section === 'community-news' || section === 'local-news'

    const headerRowRef = React.useRef<HTMLDivElement>(null)
    const ngnRedesignLeaderboardAdRef = React.useRef<HTMLDivElement>(null)
    const ngnRedesignHeaderLogoRowRef = React.useRef<HTMLDivElement>(null)

    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    const ngnRedesignEnabled = isFeatureEnabled(
        featureState,
        'perthnow-ngn-redesign-master',
    )

    return (
        <Template
            inApp={inApp}
            disableStickyHeader={!!additionalPageProperties.disableStickyHeader}
            fullPageWidth={!!additionalPageProperties.fullPageWidth}
            headerRowRef={headerRowRef}
            ngnRedesignLeaderboardAdRef={ngnRedesignLeaderboardAdRef}
            ngnRedesignHeaderLogoRowRef={ngnRedesignHeaderLogoRowRef}
            header={
                <Header
                    headerRowRef={headerRowRef}
                    ngnRedesignLeaderboardAdRef={ngnRedesignLeaderboardAdRef}
                    ngnRedesignHeaderLogoRowRef={ngnRedesignHeaderLogoRowRef}
                    toggleMobileNav={toggleMobileNav}
                    mobileNavExpanded={mobileNavExpanded}
                    ads={ads}
                    onEvent={onEvent}
                    location={location}
                    ngnRedesignEnabled={ngnRedesignEnabled}
                    inApp={inApp}
                    section={section}
                    featureState={featureState}
                    services={services}
                />
            }
            primaryNav={
                <PrimaryNav
                    inApp={inApp}
                    mobileNavExpanded={mobileNavExpanded}
                    onEvent={onEvent}
                    section={section}
                    featureState={featureState}
                />
            }
            secondaryNav={
                <SecondaryNav section={section} location={location} />
            }
            windowShade={
                <WindowShade
                    ads={ads}
                    onEvent={onEvent}
                    ngnRedesignEnabled={ngnRedesignEnabled}
                />
            }
            content={
                <Content
                    additionalPageProperties={additionalPageProperties}
                    section={section}
                    pageType={pageType}
                    version={version}
                    pageContents={pageContents}
                />
            }
            footerAd={
                <FooterAd
                    ads={ads}
                    onEvent={onEvent}
                    ngnRedesignEnabled={ngnRedesignEnabled}
                />
            }
            footer={
                inApp ? (
                    <></>
                ) : (
                    <Footer isAltFooter={isAltFooter} onEvent={onEvent} />
                )
            }
            redesignEnabled={ngnRedesignEnabled}
        />
    )
}
