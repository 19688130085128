import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface PNCameraProps {
    className?: string
    width?: number
    height?: number
    horizontalSpacing?: number | undefined
}

export const StyledPNCameraIcon = styled('svg')<PNCameraProps>((props) => ({
    width: props.width ? calcRem(props.width) : undefined,
    height: props.height ? calcRem(props.height) : undefined,
    marginLeft: props.horizontalSpacing && calcRem(props.horizontalSpacing),
}))
