import React, { useMemo } from 'react'
import {
    AdUnitWrapper,
    PNHeader,
    topOfPageClassName,
    PNHeaderNGN,
    PerthNowSection,
    breakpoints,
    useWindowWidth,
} from '@news-mono/component-library'
import { FeatureState } from '@etrigan/feature-toggles-client'
import H from 'history'
import {
    AdState,
    AllEvents,
    DataLoaderGlobalParams,
} from '@news-mono/web-common'
import { SiteHeaderAd } from '../../Site.styled'
import { useNavigation, subscribeLink, findMyPaperLink } from './Navigation'
export interface BasePerthNowHeaderProps {
    headerRowRef: React.RefObject<HTMLDivElement>
    ngnRedesignLeaderboardAdRef: React.RefObject<HTMLDivElement>
    ngnRedesignHeaderLogoRowRef: React.RefObject<HTMLDivElement>
    toggleMobileNav: () => void
    mobileNavExpanded: boolean
    ads: AdState | undefined
    onEvent: (event: AllEvents) => void
    location: H.Location
    ngnRedesignEnabled: boolean
    inApp: boolean
    section: PerthNowSection
    featureState: FeatureState
    services: DataLoaderGlobalParams
}
const Header: React.FC<BasePerthNowHeaderProps> = ({
    headerRowRef,
    ngnRedesignLeaderboardAdRef,
    ngnRedesignHeaderLogoRowRef,
    toggleMobileNav,
    mobileNavExpanded,
    ads,
    onEvent,
    location,
    ngnRedesignEnabled,
    inApp,
    section,
    featureState,
    services,
}) => {
    const { mainNav, stickyNav } = useNavigation()
    const Header: React.ElementType = ngnRedesignEnabled
        ? PNHeaderNGN
        : PNHeader
    const windowWidth = useWindowWidth() ?? 0
    const unitIdPN = useMemo(() => {
        if (windowWidth < breakpoints.lg) {
            return 'headerTabletDesktop'
        } else {
            return 'header'
        }
    }, [windowWidth])
    const getAdsComponent = useMemo(
        () => (ads: AdState) => {
            const unitId = ngnRedesignEnabled ? unitIdPN : 'header'
            return (
                <AdUnitWrapper
                    noticePosition="top-right"
                    padding={[0, 0]}
                    unitId={unitId}
                    adState={ads}
                    onEvent={onEvent}
                    adType={'inline'}
                />
            )
        },
        [ngnRedesignEnabled, unitIdPN, onEvent],
    )

    const navLinks = useMemo(
        () =>
            inApp
                ? mainNav.filter(({ hideFromAppNav }) => !hideFromAppNav)
                : mainNav,
        [inApp, mainNav],
    )

    return (
        <Header
            className={topOfPageClassName}
            headerRowRef={headerRowRef}
            toggleMobileNav={toggleMobileNav}
            mobileNavExpanded={mobileNavExpanded}
            leaderboardAd={
                <SiteHeaderAd>{ads && getAdsComponent(ads)}</SiteHeaderAd>
            }
            controls={'PNMainNavId'}
            isHome={location.pathname === '/'}
            onEvent={onEvent}
            // Navigation state is managed by the parent component only for the NGN redesign
            ngnRedesignLeaderboardAdRef={ngnRedesignLeaderboardAdRef}
            ngnRedesignHeaderLogoRowRef={ngnRedesignHeaderLogoRowRef}
            navLinks={navLinks}
            stickyNav={stickyNav}
            section={section}
            featureState={featureState}
            subscribeLink={subscribeLink}
            findMyPaperLink={findMyPaperLink}
            services={services}
        />
    )
}

export default Header
