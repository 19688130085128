import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import {
    CardMedia,
    CardMediaProps,
} from '../../../../cards/CardMedia/CardMedia'
import { breakpointMax } from '../../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { perthNowNGNTextMixin } from '../../../../mixins/TextMixin/TextMixin.theme'
import { PNArticleCardTheme } from '../PNCard.Theme'

const { background, stroke, media, text, fill, icon } = colorsPN

interface StyledCardMediaProps extends CardMediaProps {
    isVideo: boolean
    tabIndex: number
}

export const StyledCard = styled.div(() => ({
    display: 'block',
    width: '100%',
    margin: calcRem(metricsPN.spacing['L'], 0, 0, 0),
    padding: calcRem(0, 0, metricsPN.spacing['L'], 0),

    // Responsive design for mobile screens
    [breakpointMax('md')]: {
        minWidth: 'auto',
    },
    [breakpointMax('xs')]: {
        width: '100%',
        minWidth: 'auto',
    },
}))

export const StyledAnchor = styled(WebLink)(() => {
    return {
        alignSelf: 'auto',
        textDecoration: 'none',
        display: 'inline-block',
        height: 'fit-content',
        width: '100%',

        // Focus style - Anchor doesnt like outlineStyle
        '&:focus-visible': {
            outlineColor: stroke.brand.strong,
            outlineOffset: metricsPN.spacing['5XS'],
            borderRadius: `${metricsPN.radius['S']}`,
        },
    }
})
export const StyledCardMedia = styled(CardMedia)<StyledCardMediaProps>(
    ({ isVideo }) => ({
        flex: `1 3 ${calcRem(106)}`,
        borderRadius: `${metricsPN.radius['M']}px`,
        margin: `0 auto ${calcRem(metricsPN.spacing['S'])} auto`,
        div: {
            height: 'fit-content',
        },
        span: {
            backgroundColor: `${icon?.primary}`,
        },
        // Hover style
        '&:hover': {
            span: {
                backgroundColor: `${background?.brand?.strong}`,
            },
        },
        '&:hover::after': {
            backgroundColor: `${media?.hover}`,
        },
        // Focus style
        '&:focus': {
            outline: `2px solid ${stroke.brand.strong}`,
            outlineOffset: calcRem(metricsPN.spacing['5XS']),
        },
        // Active style
        '&:active': {
            span: {
                backgroundColor: `${background?.brand?.weak}`,
            },
        },
    }),
)

export const StyledBadgeSpan = styled.span(() => ({
    ...perthNowNGNTextMixin?.label?.S?.semibold,
    color: `${text?.inverse}`,
    textAlign: 'center',
    backgroundColor: `${fill?.strong}`,
    borderRadius: calcRem(99),
    padding: calcRem(metricsPN.spacing['4XS'], metricsPN.spacing['XS']),
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    // Focus style
    '&:focus-visible': {
        outline: `2px solid ${stroke.brand.strong}`,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
}))

export const StyledHeader = styled.h2(() => ({
    ...perthNowNGNTextMixin?.title?.M?.regular,
    color: text?.primary,
    margin: calcRem(metricsPN.spacing['XS'], 0, 0, 0),
    // Active style
    '&:active': {
        color: text?.brand,
    },
    '&:hover': {
        textDecoration: 'underline',
    },
    // Focus style
    '&:focus-visible': {
        outline: `2px solid ${stroke.brand.strong}`,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
        borderRadius: `${metricsPN.radius['S']}px`,
    },
    [breakpointMax('xs')]: {
        ...PNArticleCardTheme?.S?.headLine,
    },
}))

export const StyledTeaser = styled.p(() => ({
    ...perthNowNGNTextMixin?.body?.L?.regular,
    color: text?.primary,
    margin: calcRem(metricsPN.spacing['2XS'], 0, metricsPN.spacing['XS'], 0),
    // Focus style
    '&:focus-visible': {
        outline: `2px solid ${stroke.brand.strong}`,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
        borderRadius: `${metricsPN.radius['S']}px`,
    },

    [breakpointMax('xs')]: {
        ...PNArticleCardTheme?.S?.teaser,
    },
}))

export const StyledByLine = styled.span(() => ({
    ...perthNowNGNTextMixin?.body?.M?.medium,
    color: text?.secondary,
    margin: calcRem(metricsPN.spacing['4XS'], 0, 0, 0),
    // Focus style
    '&:focus-visible': {
        outline: `2px solid ${stroke.brand.strong}`,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
    [breakpointMax('xs')]: {
        ...PNArticleCardTheme?.S?.topic,
    },
}))

export const StyledInnerWrapper = styled.div(() => ({
    height: '100%',
    width: '100%',
    display: 'inline',
    div: {
        color: text?.primary,
        ':first-child': {
            textTransform: 'uppercase',
        },
        ...perthNowNGNTextMixin?.body?.M?.semibold,
    },
}))
