import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ConsentLevel,
    ExtendedAccessEvent,
    HeroVideo,
    ImageSourceWithCaptions,
    PlayerEvent,
    useConsentGiven,
    useToggleState,
    VideoDetails,
} from '@news-mono/web-common'
import React from 'react'
import {
    AutoplayOptions,
    GetVideoQueue,
    VideoOptions,
} from '../../../../content/Video/PlayerInterface'
import { useGdprMode } from '../../../../content/Video/utils/gdpr-mode'
import { FigcaptionRenderOptions } from '../../../../publication/ArticleImage/ArticleImage'
import { FetchPriority } from '../../../../content/Picture/Image.web.types'
import { PNArticleImage } from '../PNArticleImage/PNArticleImage'
import { PNArticleVideo } from '../PNArticleVideo/PNArticleVideo'
import { getPreferredCredit } from '../../../../publication/ArticleHero/ArticleHero'

export interface PNArticleHeroProps {
    heading: string
    heroImage?: ImageSourceWithCaptions
    heroVideo?: HeroVideo
    onEvent: (event: PlayerEvent | ExtendedAccessEvent) => void
    isVideoStickyEnabled?: boolean
    adUnitPath: string

    /**
     * Allows component to override defaults (including values defaulted by toggles)
     */
    autoplayOptionsOverrides?: false | Partial<AutoplayOptions>
    autoplayNextOptionssOverrides?: false | Partial<AutoplayOptions>

    showVideoTitles?: boolean
    getVideoQueue: GetVideoQueue
    publicationPathname?: string

    preferredCaptionSource?: 'image' | 'video'
    figcaptionRenderOptions?: FigcaptionRenderOptions
    imageRenderType?: 'feature'
    showVideoPlaylist?: boolean
    imageFetchPriority?: FetchPriority
}

export const PNArticleHero = (props: PNArticleHeroProps) => {
    const {
        showVideoTitles,
        heroVideo,
        heroImage,
        isVideoStickyEnabled,
        getVideoQueue,
        publicationPathname,
        adUnitPath,
        autoplayOptionsOverrides = {},
        preferredCaptionSource = 'video',
        figcaptionRenderOptions,
        imageRenderType,
        imageFetchPriority,
    } = props
    const toggles = useToggleState()

    const gdprMode = useGdprMode()
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Advertising | ConsentLevel.Analytics,
    })
    const canLoadVideoPlayer = gdprMode || consentGiven

    if (canLoadVideoPlayer && heroVideo) {
        const { videoId, heading } = heroVideo

        const videoDetails: VideoDetails = {
            id: videoId,
            accountId: heroVideo.accountId,
            heading,
            posterImage: heroVideo.posterImage,
            source: heroVideo.source,
            videoType: heroVideo.videoType,
        }

        const isAutoPlay = isFeatureEnabled(
            toFeatureState(toggles),
            'can-autoplay-video',
            true,
        )

        const autoplayOptions: false | AutoplayOptions =
            autoplayOptionsOverrides !== false && isAutoPlay
                ? {
                      delayDuration: 0,
                      pauseCountdownOutsideViewport: true,
                      ...autoplayOptionsOverrides,
                  }
                : false

        const videoOptions: VideoOptions = {
            adUnitPath,
            canAutoplayVideos: false,
            enableSnowplow: false,
            enablePrebid: isFeatureEnabled(
                toFeatureState(toggles),
                'prebid-video',
            ),
            isMagniteEnabled: isFeatureEnabled(
                toFeatureState(toggles),
                'magnite-header-tag-wrapper',
            ),
            gdprMode,
        }

        return (
            <PNArticleVideo
                showTitles={Boolean(showVideoTitles)}
                videoDetails={videoDetails}
                autoplayOptions={autoplayOptions}
                autoplayNextOptions={false}
                videoOptions={videoOptions}
                captionText={
                    heroVideo
                        ? heroVideo.captionText
                        : heroImage
                        ? heroImage.captionText
                        : undefined
                }
                credit={
                    heroImage === undefined
                        ? getPreferredCredit(
                              preferredCaptionSource,
                              heroImage,
                              heroVideo,
                          )
                        : undefined
                }
                byline={heroVideo.byline}
                kind="video"
                isHeroVideo={true}
                pauseOverlay
                getVideoQueue={getVideoQueue}
                isVideoStickyEnabled={isVideoStickyEnabled}
                onEvent={props.onEvent}
                publicationPathname={publicationPathname}
                figcaptionRenderOptions={figcaptionRenderOptions}
            />
        )
    }

    if (heroImage) {
        return (
            <PNArticleImage
                image={heroImage}
                imageFetchPriority={imageFetchPriority}
                figCaption={{
                    byline: heroImage.byline,
                    captionText: heroImage.captionText,
                    credit: heroImage.credit,
                    kind: 'image',
                }}
                isHeroImage={true}
                figcaptionRenderOptions={figcaptionRenderOptions}
                imageRenderType={imageRenderType}
            />
        )
    }

    return (
        <PNArticleImage
            isHeroImage={true}
            figcaptionRenderOptions={figcaptionRenderOptions}
            figCaption={{}}
            imageRenderType={imageRenderType}
        />
    )
}
PNArticleHero.displayName = 'PNArticleHero'
