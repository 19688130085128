import { useMemo, useState } from 'react'
import { PerthNowNavItem } from '@news-mono/component-library'
import { StaticNavInHouseLinks, useToggleState } from '@news-mono/web-common'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    navigationOriginalTaxonomyNGN,
    navigation,
    navigationMobileTabletNGN,
    navigationNewTaxonomyNGN,
    navigationMobileTabletNewTaxonomyNGN,
} from './navigation-items'

export const findMyPaperLink =
    '/editions?utm_source=PerthNow&utm_medium=Referral&utm_campaign=digital-edition&utm_term=digitalprinteditions&utm_content=header-nav'

export const generateMainNav = (
    navigationMeta: PerthNowNavItem[],
): PerthNowNavItem[] => {
    return navigationMeta
        .filter((item) => !item.hideFromMainNav)
        .map((item) => ({
            ...item,
            subNavLinks: item.subNavLinks
                ? item.subNavLinks
                      .filter((subItem) => !subItem.hideFromMainNav)
                      .map((subItem) => ({
                          ...subItem,
                          subNavLinks: subItem.subNavLinks
                              ? subItem.subNavLinks.filter(
                                    (subSubItem) => !subSubItem.hideFromMainNav,
                                )
                              : undefined,
                      }))
                : undefined,
        }))
}

export const staticNavInHouseLinks: StaticNavInHouseLinks = []

export const subscribeLink = '/manage-email-preferences'

// Navigation Hook
export const useNavigation = () => {
    const featureState = toFeatureState(useToggleState())
    const ngnRedesignEnabled = useState(
        isFeatureEnabled(featureState, 'perthnow-ngn-redesign-master'),
    )

    const useNewTaxonomy = useState(
        isFeatureEnabled(featureState, 'perthnow-ngn-new-taxonomy'),
    )

    const mainNav = useMemo<PerthNowNavItem[]>(() => {
        let navigationMeta = navigation

        if (ngnRedesignEnabled[0]) {
            if (useNewTaxonomy[0]) {
                navigationMeta = navigationNewTaxonomyNGN
            } else {
                navigationMeta = navigationOriginalTaxonomyNGN
            }
        }

        return generateMainNav(navigationMeta)
    }, [ngnRedesignEnabled, useNewTaxonomy]) as PerthNowNavItem[]

    const stickyNav = useMemo<PerthNowNavItem[]>(() => {
        const stickyNavigationMeta = useNewTaxonomy
            ? navigationMobileTabletNewTaxonomyNGN
            : navigationMobileTabletNGN
        return generateMainNav(stickyNavigationMeta)
    }, [useNewTaxonomy])
    return {
        mainNav,
        stickyNav,
    }
}
