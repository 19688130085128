import React from 'react'
import { StyledMain, StyledSidebar, StyledPNHades } from './PNHades.styled'
import { BaseCompositionProps } from '../types'
import { PNSectionHeader } from '../../nextgen-news/perthnow/PNSectionHeader/PNSectionHeader'
import { SectionHeaderNavLink } from '../../collections'

export interface PNHadesHeader {
    heading: string
    headingUrl?: string
    mainSection?: boolean
    navLinks?: SectionHeaderNavLink[]
}
export interface PNHadesProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarContent: React.ReactElement<any>
    // need to accommodate both evergreen layout and article page
    layout?: 'homepage' | 'articlePage'
    heading?: PNHadesHeader
}

export const PNHades: React.FC<PNHadesProps> = ({
    mainContent,
    sidebarContent,
    heading,
    layout = 'homepage',
}) => {
    return (
        <>
            {heading && (
                <PNSectionHeader
                    heading={heading?.heading}
                    headingUrl={heading.headingUrl}
                    mainSection={heading.mainSection}
                    navLinks={heading.navLinks}
                />
            )}
            <StyledPNHades>
                <StyledMain layout={layout}>{mainContent}</StyledMain>
                <StyledSidebar layout={layout}>{sidebarContent}</StyledSidebar>
            </StyledPNHades>
        </>
    )
}
PNHades.displayName = 'PNHades'
