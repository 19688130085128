import React from 'react'
import { StyledIconPNImage, StyledIconPNVideo } from './PNFigCaption.styled'

export function getPNTypeAndIcon(
    showMediaIcon: boolean,
    kind: string | undefined,
    captionText: string | undefined,
) {
    let mediaType = null
    let mediaIcon = null

    if (showMediaIcon) {
        mediaType = captionText ? ' Credit: ' : 'Credit: '

        if (kind === 'video') {
            mediaIcon = <StyledIconPNVideo />
        } else if (kind === 'image') {
            mediaIcon = <StyledIconPNImage />
        } else if (kind === 'hero-image') {
            mediaType = 'Main Image: '
            mediaIcon = <StyledIconPNImage />
        }
    }

    return { mediaType, mediaIcon }
}
