import { adPaddingOptions, PerthNowSection } from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    publicationAdKind,
    RenderTarget,
    TogglesReduxState,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { sidebarCollectionAroundTheSite } from '../../common/sidebarCollection'
import { getNoIndexMeta } from './allowList'
import { toFeatureState } from '@etrigan/feature-toggles-client'

export const createArticleRouteNgn = ({
    article,
    config,
    section,
    getAdTargeting,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: PerthNowSection
    getAdTargeting: GetRouteAdTargeting
    renderTarget: RenderTarget
    toggles?: TogglesReduxState
}): PageType<PerthNowSection> => {
    const featureState = toggles && toFeatureState(toggles)
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        publicationAdKind[article.kind],
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            title: article.seoHead || undefined,
            description: article.homepageTeaser,
            meta: getNoIndexMeta(article.source, featureState),
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        section,
        additionalPageProperties: {
            primaryTopic: article.topics.primary,
            secondaryTopics: article.topics.secondary,
            publicationKind: article.kind,
        },
        compositions: [
            layout.composition({
                type: 'pn-hades',
                props: {
                    layout: 'articlePage',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'ad-unit',
                            feature: 'outstream',
                            props: {
                                noticePosition: 'none',
                                hiddenUntilLoaded: false,
                                padding: adPaddingOptions['thewest'],
                                slot: {
                                    id: 'outstream2x2',
                                    size: 'outstream',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'article',
                            props: {
                                meta: {
                                    slug: article.slug,
                                    kind: 'article',
                                    inlinePublicationContent: [],
                                },
                                dataDefinitionArgs: {
                                    publicationId: article.id,
                                },
                                section,
                            },
                        }),
                        layout.component({
                            type: 'coral',
                            feature: 'comments',
                            props: {
                                storyID: `${config.publicHostname}-${article.id}`,
                                articleCommentsEnabled: article.allowCommenting,
                                storyURL: `${article._self}`,
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        sidebarCollectionAroundTheSite({
                            pageSize: 4,
                            offset: 0,
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [13, 0],
                                stickyOffset: 0,

                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
