import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    PublicationCardItem,
    toLinkWithHint,
    WebLink,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../../../cards/CardItem.Props'
import { CardHeadlineSkeleton } from '../../../../cards/CardText/CardHeadlineSkeleton'
import {
    StyledCardMedia,
    StyledCardTextContainer,
    StyledLink,
    StyledHeadline,
    StyledThemeCard,
    StyledTeaser,
    StyledStreamerBHPLogo,
    StyledStreamerScreamerLogo,
    StyledBannerWrapper,
    StyledLogoLink,
} from './ThemeCard.styled'
import { PNThemeCardTheme, cardThemes } from './themes'

const StreamerScreamerLogoSource = require('../../../../logos/StreamerScreamerLogo/streamerscreamerlogo.svg')
const BHPLogoSource = require('../../../../logos/StreamerScreamerLogo/bhplogo.svg')

export interface PNThemeCardProps extends CommonCardProps {
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    disableImageLazyLoad?: boolean
    cardNumber: number
    innerRef?: React.RefObject<any>
    item: MaybeLoaded<PublicationCardItem>
    theme: PNThemeCardTheme
}

const streamerBanner = (
    <StyledBannerWrapper>
        <StyledLogoLink to="https://streamer.com.au">
            <StyledStreamerScreamerLogo src={StreamerScreamerLogoSource} />
        </StyledLogoLink>
        <StyledLogoLink to="https://streamer.com.au">
            <StyledStreamerBHPLogo src={BHPLogoSource} />
        </StyledLogoLink>
    </StyledBannerWrapper>
)

type BannerMap = {
    [key in PNThemeCardTheme]: JSX.Element | undefined
}

export const banners: BannerMap = {
    'streamer-screamer': streamerBanner,
    default: undefined,
}

export const PNThemeCard: React.FC<PNThemeCardProps> = ({
    className,
    item,
    fixedRatio,
    disableImageLazyLoad,
    onEvent,
    cardNumber,
    innerRef,
    theme,
}) => {
    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                `PNThemeCard - ${theme}`,
                'ThemeCard',
                cardNumber,
            ),
        )
    }
    if (item.loaded) {
        return (
            <StyledThemeCard
                ref={innerRef}
                className={className}
                data-topic={
                    item.loaded ? item.result.primaryTopic.id : undefined
                }
                cardTheme={theme}
            >
                <StyledCardMedia
                    item={item}
                    fixedRatio={fixedRatio}
                    imageWidths={{
                        mobile: '100vw',
                        tablet: '100vw',
                        desktop: '100vw',
                        fallbackWidth: 628,
                    }}
                    onEvent={onEvent}
                    disableImageLazyLoad={disableImageLazyLoad}
                />
                {cardThemes[theme].banner ? banners[theme] : undefined}
                <StyledCardTextContainer cardTheme={theme}>
                    {item.loaded ? (
                        <>
                            <StyledHeadline>
                                {item.result.headline}
                            </StyledHeadline>
                            <StyledTeaser>{item.result.teaser}</StyledTeaser>
                            <StyledLink
                                to={toLinkWithHint(item)}
                                onClick={linkClicked}
                                cardTheme={theme}
                            >
                                {item.result.kicker}
                            </StyledLink>
                        </>
                    ) : (
                        <CardHeadlineSkeleton fontScale={1} />
                    )}
                </StyledCardTextContainer>
            </StyledThemeCard>
        )
    }
    return null
}
PNThemeCard.displayName = 'PromotionalCard'
