import styled, { CSSObject } from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { breakpoint } from '../../../../__styling'
import { IconPNCamera } from '../../../../icons'
import { IconPNVideo } from '../../../../icons'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../__styling/style-mixins'
import { Theme } from '../../../../__styling/themes'
import { PNFigCaption } from './PNFigCaption'
import { perthNowNGNTextMixin } from '../../../../mixins'

const { spacing } = metricsPN
const { text } = colorsPN

const iconWidth = spacing.S
const iconMarginRight = spacing['2XS']
const iconMarginTop = spacing['5XS']

export interface StyledPNFigCaptionProps {
    isHero?: boolean
    // "as" this is available on emotion 10 but we are currently overloading with emotion 9 to save build time
    // https://emotion.sh/docs/styled
    as?: string
}

export const StyledPNCaption = styled('figcaption')<StyledPNFigCaptionProps>(
    ({ isHero }) => [
        {
            breakWords,
            '&::after': {
                content: `''`,
                display: 'table',
                clear: 'both',
            },
        },
    ],
)

export const StyledPNFigCaptionText = styled('span')(({ theme }) => ({
    ...breakWords,
    display: 'block',
    float: 'left',
    maxWidth: '100%', // needed to break long words in IE and Edge
    width: `calc(100% - (${calcRem(iconWidth)} + ${calcRem(iconMarginRight)}))`,
    ...perthNowNGNTextMixin.body.S.regular,

    span: {
        color: colorsPN.text.secondary,
    },
    '& > span:last-child': {
        display: 'block',
    },
}))

export const PNCaption = styled('span')({
    display: 'inline',
})
PNCaption.displayName = 'PNCaption'

const iconStyles: CSSObject = {
    width: calcRem(iconWidth),
    height: calcRem(iconWidth),
    display: 'block',
    verticalAlign: 'middle',
    float: 'left',
    margin: calcRem(iconMarginTop, iconMarginRight, 0, 0),
}

export const StyledIconPNImage =
    styled(IconPNCamera)<StyledPNFigCaptionProps>(iconStyles)

export const StyledIconPNVideo =
    styled(IconPNVideo)<StyledPNFigCaptionProps>(iconStyles)

export interface StyledPNFigureProps {
    isHero?: boolean
    align?: 'right' | 'left'
    isTwin?: boolean
    imageRenderType?: string
}
export const StyledPNFigcaption = styled(PNFigCaption)<StyledPNFigureProps>(
    ({ isHero }) => ({
        color: text.secondary,
        padding: calcRem(spacing.XS, spacing.S, 0, spacing.S),

        [breakpoint('sm')]: {
            padding: calcRem(spacing.S),
        },
    }),
)
