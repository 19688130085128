import React from 'react'
import { AmpVideo } from '../../../../content/Video/Video.amp'
import { PNArticleVideoProps } from './PNArticleVideo'
import {
    pnArticleVideoClassName,
    StyledPNArticleVideo,
    StyledPNArticleVideoContainer,
} from './PNArticleVideo.styled'
import { StyledPNFigcaption } from '../PNFigCaption/PNFigCaption.styled'

export const PNAmpArticleVideo: React.FC<PNArticleVideoProps> = ({
    byline,
    captionText,
    credit,
    kind,
    videoDetails,
    isHeroVideo,
    publicationPathname,
    videoOptions,
}) => {
    if (videoDetails.id) {
        return (
            <StyledPNArticleVideo
                className={pnArticleVideoClassName}
                isHeroVideo={isHeroVideo}
            >
                <StyledPNArticleVideoContainer isHeroVideo={isHeroVideo}>
                    <AmpVideo
                        videoDetails={videoDetails}
                        width={480}
                        height={270}
                        videoOptions={videoOptions}
                        publicationPathname={publicationPathname}
                    />
                </StyledPNArticleVideoContainer>

                {(captionText || credit || byline) && (
                    <StyledPNFigcaption
                        byline={byline}
                        captionText={captionText}
                        credit={isHeroVideo ? undefined : credit}
                        isHero={isHeroVideo}
                        kind={kind || 'video'}
                    />
                )}
            </StyledPNArticleVideo>
        )
    }

    return null
}
export default PNAmpArticleVideo
