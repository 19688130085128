import styled from '@emotion/styled'
import { WebNavLink } from '@news-mono/web-common'
import { calcRem, breakpointMax } from '@news-mono/component-library'
import { perthNowNGNTextMixin } from '../../../../mixins/TextMixin/TextMixin.theme'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'

export interface StyledListItemProps {
    isCurrentSection: boolean
    isOpen: boolean
}

const getParentNavStyles = (isCurrentSection: boolean, isOpen: boolean) =>
    ({
        ...perthNowNGNTextMixin.label.L.medium,
        whiteSpace: 'nowrap',
        position: 'relative',
        textTransform: 'capitalize',
        height: '100%',
        alignContent: 'center',
        padding: calcRem(metricsPN.spacing['XS']),
        color: isOpen ? colorsPN.text.brand : colorsPN.text.primary,
        textDecoration: isCurrentSection ? 'underline' : 'none',
        textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
        textDecorationColor: colorsPN.text.brand,
        textDecorationThickness: calcRem(2),
        '&:hover': {
            color: colorsPN.text.brand,
        },
        '&:focus-visible': {
            outline: `2px solid ${colorsPN.stroke.brand.strong}`,
            borderRadius: `${metricsPN.radius['S']}px`,
        },
        [breakpointMax('lg')]: {
            padding: calcRem(metricsPN.spacing['S']),
        },
    } as const)

export const StyledListItem = styled('li')(() => ({
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
}))

export const StyledButton = styled.button<StyledListItemProps>(
    ({ isOpen, isCurrentSection }) => ({
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        ...getParentNavStyles(isCurrentSection, isOpen),
        '&:active': {
            color: colorsPN.text.pressed,
        },
    }),
)

export const StyledLink = styled(WebNavLink)<StyledListItemProps>(
    ({ isOpen, isCurrentSection }) => ({
        ...getParentNavStyles(isCurrentSection, isOpen),
    }),
)
