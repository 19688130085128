import {
    AppProps,
    AuthenticationHandler,
    isNextGenPNEnabled,
    PageResolver,
    PerthNowSection,
    ProvideRouteResolvedDataToTheme,
} from '@news-mono/component-library'
import {
    errorLocation,
    getRedirectRouteInfo,
    matchedErrorRoutePages,
    Product,
    registerPageRoutes,
    RenderTargetContext,
    StaticRoutes,
} from '@news-mono/web-common'
import { isArticleLikePublication } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useLocation } from 'react-router'
import './App.css'
import { layout } from './App.routing'
import { staticRoutes } from './routes'
import { createDiagPage } from './routes/diag-routes'
import {
    matchedSomethingWentWrongRoute,
    notFoundRoute,
    staticSomethingWentWrongRoute,
} from './routes/errors'
import { getProfilePageRouteInfo } from './routes/profile/get-profile-page-route-info'
import { createArticleRoute } from './routes/publication/kind/article'
import { createGalleryRoute } from './routes/publication/kind/gallery'
import { getTopicPageRouteInfo } from './routes/topic/get-topic-page-route-info'
import { Site, siteAds } from './Site'
import { datadogRum } from '@datadog/browser-rum'
import { createArticleRouteNgn } from './routes/publication/kind/ngn-article'

export function initRouting() {
    registerPageRoutes<PerthNowSection>({
        staticRoutes,
        matchRoutes: {
            '/diag$': ({ config, resolution }) => {
                const section =
                    resolution.type === 'match' && resolution.section
                        ? resolution.section
                        : 'default'
                return createDiagPage(config, section as PerthNowSection)
            },
            // The following are required for sectioned something went wrong pages
            [`${errorLocation}$`]: ({ config, getAdTargeting, resolution }) =>
                matchedSomethingWentWrongRoute(
                    config,
                    getAdTargeting,
                    resolution,
                ),
            ...(matchedErrorRoutePages as StaticRoutes<PerthNowSection>),
        },
        serverRoutes: {
            profile: ({
                config,
                getAdTargeting,
                resolution,
                location,
                store,
            }) => {
                return getProfilePageRouteInfo({
                    profile: resolution.resolution.profile,
                    section: resolution.resolution.section as PerthNowSection,
                    config,
                    getAdTargeting,
                    location,
                    store,
                })
            },
            redirect: ({ resolution }) => {
                return getRedirectRouteInfo(
                    resolution.resolution.redirectTo.targetUrl,
                )
            },
            topic: ({ resolution, getAdTargeting, store }) => {
                // I'm not sure if this is the best place to put this.
                const { toggles } = store.getState()

                return getTopicPageRouteInfo({
                    publicationKind: resolution.resolution.publicationKind,
                    topic: resolution.resolution.topic,
                    section: resolution.resolution.section as PerthNowSection,
                    getAdTargeting,
                    toggles,
                })
            },
            publication: ({
                resolution,
                config,
                getAdTargeting,
                renderTarget,
                store,
            }) => {
                const { toggles } = store.getState()
                const articlePageRedesignEnabled = isNextGenPNEnabled(
                    toggles,
                    'perthnow-ngn-article-disabled',
                )
                const getArticleRoute = articlePageRedesignEnabled
                    ? createArticleRouteNgn
                    : createArticleRoute
                if (
                    resolution.resolution.publication.kind === 'redirect' &&
                    resolution.resolution.publication.redirectUrl
                ) {
                    return getRedirectRouteInfo(
                        resolution.resolution.publication.redirectUrl,
                    )
                }
                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    return getArticleRoute({
                        config,
                        article: resolution.resolution.publication,
                        section: resolution.resolution
                            .section as PerthNowSection,
                        getAdTargeting,
                        renderTarget,
                        toggles,
                    })
                }
                if (resolution.resolution.publication.kind === 'gallery') {
                    return createGalleryRoute({
                        gallery: resolution.resolution.publication,
                        numberGalleryItems:
                            resolution.resolution.publication.imageCount,
                        section: resolution.resolution
                            .section as PerthNowSection,
                        getAdTargeting,
                    })
                }
                return null
            },
            preview: ({
                resolution,
                config,
                getAdTargeting,
                renderTarget,
                store,
            }) => {
                const { toggles } = store.getState()

                const articlePageRedesignEnabled = isNextGenPNEnabled(
                    toggles,
                    'perthnow-ngn-article-disabled',
                )
                const getArticleRoute = articlePageRedesignEnabled
                    ? createArticleRouteNgn
                    : createArticleRoute

                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    return getArticleRoute({
                        config,
                        article: resolution.resolution.publication,
                        section: resolution.resolution
                            .section as PerthNowSection,
                        getAdTargeting,
                        renderTarget,
                        toggles,
                    })
                }
                if (resolution.resolution.publication.kind === 'gallery') {
                    return createGalleryRoute({
                        gallery: resolution.resolution.publication,
                        numberGalleryItems:
                            resolution.resolution.publication.imageCount,
                        section: resolution.resolution
                            .section as PerthNowSection,
                        getAdTargeting,
                        toggles,
                    })
                }

                return null
            },
            'video-series': () => {
                // TODO DPT-1990 implement video series landing page
                return null
            },
            'video-series-episode': () => {
                // TODO DPT-1991 implement video series episode page
                return null
            },
            video: () => {
                return null
            },
            'video-topic': () => {
                return null
            },
            'match-centre': () => {
                return null
            },
            'afl-match-centre': () => null,
            editions: () => {
                return null
            },
        },
        notFoundRouteInfo: notFoundRoute,
        errorRouteInfo: staticSomethingWentWrongRoute,
    })
}

initRouting()

export const App: React.FC<AppProps> = ({
    onEvent,
    hostname,
    protocol,
    services,
    gptApi,
}) => {
    if (services.config.datadogEnv !== undefined) {
        datadogRum.init({
            applicationId: '20829883-831f-40a6-a3f0-dca45b20a06e',
            clientToken: 'pub5d6f7dcd5012945896c36d582dc107cf',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: 'perthnow',
            env: services.config.datadogEnv,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 1,
            sessionReplaySampleRate: 0, // https://wanews.atlassian.net/browse/DPT-1646
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',

            /**
             * disable tracing: we're seeing it produce errors:
             * ```
             * Failed to load resource: Request header field traceparent
             * is not allowed by Access-Control-Allow-Headers.
             * ```
             * */
            allowedTracingUrls: [],
        })
    }
    const location = useLocation()
    const { renderTarget } = React.useContext(RenderTargetContext)

    return (
        <PageResolver
            layout={layout}
            hostname={hostname}
            protocol={protocol}
            location={location}
            onEvent={onEvent}
            siteAds={siteAds}
            services={services}
            renderTarget={renderTarget}
            gptApi={gptApi}
            renderPage={(
                pageContents,
                ads,
                pageType,
                section,
                additionalPageProperties,
            ) => (
                <ProvideRouteResolvedDataToTheme
                    product={Product.PerthNow}
                    section={section}
                >
                    <AuthenticationHandler
                        log={services.log}
                        config={services.config}
                        onEvent={onEvent}
                    />
                    <Site
                        pageType={pageType}
                        section={section as PerthNowSection}
                        additionalPageProperties={additionalPageProperties}
                        publicUrl={services.config.publicUrl}
                        onEvent={onEvent}
                        pageContents={pageContents}
                        location={location}
                        ads={ads}
                        config={services.config}
                        hostname={hostname || ''}
                        services={services}
                    />
                </ProvideRouteResolvedDataToTheme>
            )}
        />
    )
}
