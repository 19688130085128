import React from 'react'
import { PageProps } from 'page-lifecycle-provider'
import { PNMainNavigation } from '@news-mono/component-library'
import { useNavigation, subscribeLink } from './Navigation'

export const PrimaryNav: React.FC<any> = ({
    inApp,
    mobileNavExpanded,
    onEvent,
    section,
    featureState,
}) => {
    const { mainNav } = useNavigation()
    return (
        <PageProps
            pageProperties={{
                navName: 'Default.PerthNow',
            }}
        >
            <PNMainNavigation
                navLinks={
                    inApp
                        ? mainNav.filter(
                              ({ hideFromAppNav }) => !hideFromAppNav,
                          )
                        : mainNav
                }
                mobileNavExpanded={mobileNavExpanded}
                onEvent={onEvent}
                currentSection={section}
                subscribeLink={subscribeLink}
                featureState={featureState}
            />
        </PageProps>
    )
}
export default PrimaryNav
