import {
    AdUnitWrapper,
    AuthenticationProvider,
    CleanupTruskin,
    CookieConsentManager,
    DebugAdUnitsList,
    FeatureToggle,
    GoogleNativeAppPrompt,
    HeaderWrapper,
    isTabletOrMobileBrowser,
    LazyTheWestLiveStickyBanner,
    LiveRampATS,
    OptaAflApiProvider,
    PerthNowSection,
    ScrollToTopOnNav,
    TaboolaScriptProvider,
    TheWestLiveProvider,
    UserAnalytics,
} from '@news-mono/component-library'
import {
    AdDefinition,
    AdState,
    AllEvents,
    BaseClientConfig,
    Product,
    RenderTargetContext,
    usePrevious,
    DataLoaderGlobalParams,
} from '@news-mono/web-common'
import H from 'history'
import React from 'react'
import { BasePerthNow } from './BasePerthNow/BasePerthNow'
import { StyledSite } from './Site.styled'

export interface SiteProps {
    pageContents: React.ReactElement<any>
    pageType: string
    additionalPageProperties: {
        [key: string]: any
    }
    section: PerthNowSection
    location: H.Location
    onEvent: (event: AllEvents) => void
    publicUrl: string
    hostname: string
    ads: AdState | undefined
    config: BaseClientConfig
    services: DataLoaderGlobalParams
}

const headerUnit: AdDefinition = {
    id: 'header',
    size: 'header',
    isSitewideAd: true,
}
const headerTabDeskUnit: AdDefinition = {
    id: 'headerTabletDesktop',
    size: 'leaderboard768Above',
    isSitewideAd: true,
}
const footerUnit: AdDefinition = {
    id: 'footer',
    size: 'leaderboard768Above',

    isSitewideAd: true,
}
const windowShade: AdDefinition = {
    id: 'window-shade',
    size: 'windowShade',
    isSitewideAd: true,
}
const tabletHeader: AdDefinition = {
    id: 'tablet-header',
    size: 'leaderboard768Above1000Below',
    isSitewideAd: true,
}
const skinsUnit: AdDefinition = {
    id: 'skins',
    size: 'skins',
    isSitewideAd: true,
    disableLazyLoading: true,
}
const stickyFooter: AdDefinition = {
    id: 'sticky-footer',
    size: 'mobileLeaderboard',
    isSitewideAd: true,
}

const twlStickyPlayerUnit: AdDefinition = {
    id: 'twl-player',
    size: 'theWestLiveLogo',
}
const twlLandingPageUnit: AdDefinition = {
    id: 'twl-landing',
    size: 'theWestLiveLogo',
}
const twlBannerPageUnit: AdDefinition = {
    id: 'twl-banner',
    size: 'theWestLiveLogo',
}

export const siteAds: AdDefinition[] = [
    skinsUnit,
    headerUnit,
    headerTabDeskUnit,
    tabletHeader,
    windowShade,
    footerUnit,
    stickyFooter,
    twlStickyPlayerUnit,
    twlLandingPageUnit,
    twlBannerPageUnit,
]

export const Site: React.FC<SiteProps> = ({
    location,
    additionalPageProperties,
    ads,
    onEvent,
    section,
    pageContents,
    pageType,
    config,
    hostname,
    services,
}) => {
    const [mobileNavExpanded, setMobileNavExpanded] = React.useState(false)
    const previousLocation = usePrevious(location)
    const publicUrl = config.publicUrl
    const pageUrl = `${publicUrl}${location.pathname}`
    const { renderTarget } = React.useContext(RenderTargetContext)
    const isMobileOrTabletBrowser = isTabletOrMobileBrowser(renderTarget)

    React.useEffect(() => {
        if (location !== previousLocation && mobileNavExpanded) {
            setMobileNavExpanded(false)
        }
    }, [location, previousLocation, mobileNavExpanded])

    const toggleMobileNav = () => setMobileNavExpanded(!mobileNavExpanded)

    // Smartocto Integration
    React.useEffect(() => {
        if (location !== previousLocation) {
            setTimeout(() => window.postMessage('activateTentacles'), 3000)
        }
    }, [location, previousLocation])

    const basePerthNow = (
        <StyledSite className="js-ts-base">
            <TaboolaScriptProvider>
                <OptaAflApiProvider location={location}>
                    <ScrollToTopOnNav />
                    <CleanupTruskin />
                    <AuthenticationProvider>
                        <BasePerthNow
                            additionalPageProperties={additionalPageProperties}
                            ads={ads}
                            config={config}
                            location={location}
                            mobileNavExpanded={mobileNavExpanded}
                            onEvent={onEvent}
                            pageContents={pageContents}
                            pageType={pageType}
                            pageUrl={pageUrl}
                            product={Product.PerthNow}
                            section={section}
                            toggleMobileNav={toggleMobileNav}
                            version={{ buildNumber: '', sha: '' }}
                            publicUrl={publicUrl}
                            hostname={hostname}
                            services={services}
                        />
                    </AuthenticationProvider>

                    <FeatureToggle
                        feature="debug-ads"
                        on={() => (
                            <DebugAdUnitsList
                                ads={ads}
                                pathname={location.pathname}
                            />
                        )}
                    />
                </OptaAflApiProvider>
            </TaboolaScriptProvider>
        </StyledSite>
    )

    return (
        // js-ts-base added for TruSkin modifications
        /**
         * Section Context provider is hardcoded as default due to perthnow requiring default kickers
         * on all collection cards, collections are driven by sectionheader specifications for coloring
         * so section is only required for header, footer, nav elements and not content for now.
         *
         * See Seb if more clarification is required
         */
        <React.Fragment>
            {isMobileOrTabletBrowser && (
                <FeatureToggle
                    feature="app-smart-banner-android"
                    on={() => <GoogleNativeAppPrompt />}
                />
            )}
            <UserAnalytics />
            <LiveRampATS />
            <HeaderWrapper section={section} product={Product.PerthNow} />
            <FeatureToggle
                feature="the-west-live"
                on={() => (
                    <TheWestLiveProvider
                        stationId={config.theWestLiveStationId}
                        onEvent={onEvent}
                    >
                        {basePerthNow}
                        <LazyTheWestLiveStickyBanner
                            showSponsorLogo={true}
                            bannerAd={(rendered) =>
                                ads && (
                                    <AdUnitWrapper
                                        noticePosition="none"
                                        padding={[4, 0, 0, 0]}
                                        adState={ads}
                                        unitId="twl-player"
                                        unitRendered={rendered}
                                        onEvent={onEvent}
                                        adType={'inline'}
                                    />
                                )
                            }
                        />
                    </TheWestLiveProvider>
                )}
                off={() => basePerthNow}
            />
            <FeatureToggle
                feature="cookie-consent"
                on={() => <CookieConsentManager onEvent={onEvent} />}
            />
        </React.Fragment>
    )
}
Site.displayName = 'Site'
