export const navLinks = {
    original: {
        'wa news': [
            {
                text: 'Perth',
                link: '/news/perth',
            },
            {
                text: `What's On`,
                link: '/business/west-business-events',
            },
            {
                text: 'Social',
                link: '/lifestyle/social-pictures',
            },
            {
                text: 'Traffic',
                link: '/news/traffic',
            },
            {
                text: 'Weather',
                link: '/news/weather',
            },
        ],
        entertainment: [
            {
                text: 'Celebrity Gossip',
                link: '/entertainment/celebrity',
            },
            {
                text: 'Royals',
                link: '/news/royal-family',
            },
            {
                text: 'Influencers',
                link: '/lifestyle/influencers',
            },
            {
                text: 'Movies',
                link: '/entertainment/movies',
            },
            {
                text: 'TV',
                link: '/entertainment/tv',
            },
            {
                text: 'Music',
                link: '/entertainment/music',
            },
        ],
        news: [
            {
                text: 'Australia',
                link: '/news/national',
            },
            {
                text: 'Crime',
                link: '/news/crime',
            },
            {
                text: 'World',
                link: '/news/world',
            },
            {
                text: 'Business',
                link: '/business',
            },
            {
                text: 'Opinion',
                link: '/opinion',
            },
        ],
        food: [
            {
                text: 'Best Eats',
                link: '/lifestyle/restaurant-reviews',
            },
            {
                text: 'Tinnie of the Week',
                link: '/lifestyle/tinnie-of-the-week',
            },
            {
                text: 'Drink',
                link: '/lifestyle/drink',
            },
        ],
        lifestyle: [
            {
                text: 'Cash Confessions',
                link: '/lifestyle/cash-confessions',
            },
            {
                text: 'Lotto',
                link: '/news/lotto',
            },
            {
                text: 'Love & relationships',
                link: '/lifestyle/relationships',
            },
            {
                text: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                text: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                text: 'Home & garden',
                link: '/lifestyle/garden',
            },
            {
                text: 'Family life',
                link: '/lifestyle/parenting',
            },
            {
                text: 'Travel',
                link: '/travel',
            },
            {
                text: 'Beauty & style',
                link: '/lifestyle/fashion',
            },
            {
                text: 'Health & fitness',
                link: '/lifestyle/health-wellbeing',
            },
            {
                text: 'Horoscopes',
                link: '/lifestyle/horoscopes',
            },
        ],
        sport: [
            {
                text: 'AFL',
                link: '/sport/afl',
            },
            {
                text: 'Cricket',
                link: '/sport/cricket',
            },
            {
                text: 'Soccer',
                link: '/sport/soccer',
            },
            {
                text: 'Basketball',
                link: '/sport/basketball',
            },
            {
                text: 'Tennis',
                link: '/sport/tennis',
            },
            {
                text: 'NRL',
                link: '/sport/rugby-league',
            },
            {
                text: 'Rugby',
                link: '/sport/rugby-union',
            },
            {
                text: 'Motorsport',
                link: '/sport/motorsport',
            },
            {
                text: 'Racing',
                link: '/sport/horse-racing',
            },
            {
                text: 'MMA',
                link: '/sport/mixed-martial-arts',
            },
            {
                text: 'Golf',
                link: '/sport/golf',
            },
            {
                text: 'Netball',
                link: '/sport/netball',
            },
            {
                text: 'Cycling',
                link: '/sport/cycling',
            },
        ],
        'shop-now': [
            {
                text: 'Fashion',
                link: '/shop-now/shopnow-fashion',
            },
            {
                text: 'Beauty',
                link: '/shop-now/shopnow-beauty',
            },
            {
                text: 'Home & garden',
                link: '/shop-now/shopnow-home-garden',
            },
            {
                text: 'Electronics',
                link: '/shop-now/shopnow-electronics',
            },
            {
                text: 'Travel',
                link: '/shop-now/shopnow-travel',
            },
            {
                text: 'Lifestyle',
                link: '/shop-now/shopnow-lifestyle',
            },
        ],
    },
    ngn: {
        'wa news': [
            {
                text: 'Perth',
                link: '/wa/perth',
            },
            {
                text: `What's On`,
                link: '/wa/whats-on',
            },
            {
                text: 'Social',
                link: '/wa/social',
            },
            {
                text: 'Traffic',
                link: '/wa/traffic',
            },
            {
                text: 'Weather',
                link: '/wa/weather',
            },
        ],
        entertainment: [
            {
                text: 'Celebrity Gossip',
                link: '/entertainment/celebrity-gossip',
            },
            {
                text: 'Royals',
                link: '/entertainment/royals',
            },
            {
                text: 'Influencers',
                link: '/entertainment/influencers',
            },
            {
                text: 'Movies',
                link: '/entertainment/movies',
            },
            {
                text: 'TV',
                link: '/entertainment/tv',
            },
            {
                text: 'Music',
                link: '/entertainment/music',
            },
        ],
        news: [
            {
                text: 'Australia',
                link: '/news/australia',
            },
            {
                text: 'Crime',
                link: '/news/crime',
            },
            {
                text: 'World',
                link: '/news/world',
            },
            {
                text: 'Business',
                link: '/news/business',
            },
            {
                text: 'Opinion',
                link: '/news/opinion',
            },
        ],
        food: [
            {
                text: 'Best Eats',
                link: '/food/best-eats',
            },
            {
                text: 'Tinnie of the Week',
                link: '/food/tinnie-of-the-week',
            },
            {
                text: 'Drink',
                link: '/food/drink',
            },
        ],
        lifestyle: [
            {
                text: 'Cash Confessions',
                link: '/lifestyle/cash-confessions',
            },
            {
                text: 'Lotto',
                link: '/lifestyle/lotto',
            },
            {
                text: 'Love & relationships',
                link: '/lifestyle/love-relationships',
            },
            {
                text: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                text: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                text: 'Home & garden',
                link: '/lifestyle/home-garden',
            },
            {
                text: 'Family life',
                link: '/lifestyle/family-life',
            },
            {
                text: 'Travel',
                link: '/lifestyle/travel',
            },
            {
                text: 'Beauty & style',
                link: '/lifestyle/beauty-style',
            },
            {
                text: 'Health & fitness',
                link: '/lifestyle/health-fitness',
            },
            {
                text: 'Horoscopes',
                link: '/lifestyle/horoscopes',
            },
        ],
        sport: [
            {
                text: 'AFL',
                link: '/sport/afl',
            },
            {
                text: 'Cricket',
                link: '/sport/cricket',
            },
            {
                text: 'Soccer',
                link: '/sport/soccer',
            },
            {
                text: 'Basketball',
                link: '/sport/basketball',
            },
            {
                text: 'Tennis',
                link: '/sport/tennis',
            },
            {
                text: 'NRL',
                link: '/sport/rugby-league',
            },
            {
                text: 'Rugby',
                link: '/sport/rugby-union',
            },
            {
                text: 'Motorsport',
                link: '/sport/motorsport',
            },
            {
                text: 'Racing',
                link: '/sport/horse-racing',
            },
            {
                text: 'MMA',
                link: '/sport/mixed-martial-arts',
            },
            {
                text: 'Golf',
                link: '/sport/golf',
            },
            {
                text: 'Netball',
                link: '/sport/netball',
            },
            {
                text: 'Cycling',
                link: '/sport/cycling',
            },
        ],
        'shop-now': [
            {
                text: 'Fashion',
                link: '/shop-now/shopnow-fashion',
            },
            {
                text: 'Beauty',
                link: '/shop-now/shopnow-beauty',
            },
            {
                text: 'Home & garden',
                link: '/shop-now/shopnow-home-garden',
            },
            {
                text: 'Electronics',
                link: '/shop-now/shopnow-electronics',
            },
            {
                text: 'Travel',
                link: '/shop-now/shopnow-travel',
            },
            {
                text: 'Lifestyle',
                link: '/shop-now/shopnow-lifestyle',
            },
        ],
    },
}
