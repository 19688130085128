import React from 'react'
import { BylineProps } from '../../../../../publication/Byline/Byline'
import {
    StyledPNByline,
    StyledPNBylineText,
    StyledPNBylineTextWrapper,
    StyledPNHeadshotWrapper,
} from './PNByline.styled'
import {
    getProfileImage,
    Headshot,
} from '../../../../../publication/Byline/components/BylineProfile'
import { BylineTextProps } from '../../../../../publication/Byline/components/BylineText'
import { Profile } from 'web-common'
import { BylineContent } from '../../../../../publication/Byline/components/BylineContent'

export const PNByline: React.FC<BylineProps> = ({
    text,
    profile,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    allowCommenting,
    url,
    title,
    publicationKind,
}) => {
    const isEvent = publicationKind === 'event'

    return (
        <StyledPNByline>
            <PNBylineText
                profile={profile}
                fundingType={fundingType}
                source={source}
                timestamp={timestamp}
                timestampUpdated={timestampUpdated}
                timestampUpdatedLabel={isEvent ? 'Updated' : undefined}
                text={text}
                allowCommenting={allowCommenting}
            >
                {profile && <PNBylineProfile profile={profile} />}
            </PNBylineText>
        </StyledPNByline>
    )
}

const PNBylineProfile: React.FC<{ profile: Profile[] }> = ({ profile }) => {
    const profileImage = getProfileImage(profile)

    return profileImage ? (
        <StyledPNHeadshotWrapper>
            <Headshot
                source={profileImage}
                alt={profile[0] ? `Headshot of ${profile[0].name}` : undefined}
            />
        </StyledPNHeadshotWrapper>
    ) : null
}

export const PNBylineText: React.FC<BylineTextProps> = ({
    timestamp,
    timestampUpdated,
    profile,
    fundingType,
    source,
    text,
    children,
}) => {
    return (
        <StyledPNBylineText>
            <StyledPNBylineTextWrapper>
                {children}
                <BylineContent
                    profiles={profile}
                    inlineTimestamp={true}
                    fundingType={fundingType}
                    source={source}
                    timestamp={timestamp}
                    timestampUpdated={timestampUpdated}
                    timestampFormat={'dateTimeOnlyWithComma'}
                    timestampUpdatedFormat={'dateTimeOnlyWithComma'}
                    text={text}
                />
            </StyledPNBylineTextWrapper>
        </StyledPNBylineText>
    )
}
