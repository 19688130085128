import { cx } from '@emotion/css'
import React from 'react'
import { PNFigCaptionProps } from './PNFigCaption'
import {
    PNCaption,
    StyledPNCaption,
    StyledPNFigCaptionText,
} from './PNFigCaption.styled'
import { getPNTypeAndIcon } from './util'

export const PNFigCaptionWeb: React.FC<PNFigCaptionProps> = ({
    byline,
    captionText,
    className,
    credit,
    kind,
    showMediaIcon = true,
}) => {
    const { mediaType, mediaIcon } = getPNTypeAndIcon(
        showMediaIcon,
        kind,
        captionText,
    )

    // If there is a caption, add a space before the text "Credit" otherwise no space!
    const mediaLabel = `${captionText ? ' ' : ''}Credit: `

    return (
        <StyledPNCaption className={cx(className, 'figcaption')}>
            {mediaIcon}
            <StyledPNFigCaptionText>
                {captionText && <PNCaption>{captionText}</PNCaption>}
                {(credit || byline) && (
                    <span>
                        {kind !== 'hero-image' ? mediaType : mediaLabel}

                        {byline && byline}

                        {credit && byline && <span>&#47;</span>}

                        {credit && credit}
                    </span>
                )}
            </StyledPNFigCaptionText>
        </StyledPNCaption>
    )
}
