import React from 'react'
import { createRenderTarget } from '../../../../render-target'
import { PNFigCaptionWeb } from './PNFigCaption.web'

export type PNFigcaptionLayout = 'feature-article-position'

export interface PNFigCaptionProps {
    byline?: string
    captionText?: string
    credit?: string
    kind?: string
    className?: string
    separatedFromMedia?: boolean
    sticky?: boolean
    showMediaIcon?: boolean
}

export const PNFigCaption = createRenderTarget('PNFigCaption', {
    web: PNFigCaptionWeb,
    amp: PNFigCaptionWeb,
    rss: (props) => <PNFigCaptionWeb {...props} showMediaIcon={false} />,
    preview: PNFigCaptionWeb,
    app: PNFigCaptionWeb,
})
PNFigCaption.displayName = 'PNFigCaption'
