import {
    CardBreakpointRatios,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    FixedRatio,
} from '@news-mono/web-common'
import React from 'react'
import { CardRouteProps } from '../../../../cards/card-props.routing'
import { ImpressionAvailable } from '../../../../__helpers/impression-available-helper'
import { useProduct } from '../../../../__product'
import { PNThemeCard } from './ThemeCard'
import { StyledThemeCardWrapper } from './ThemeCard.styled'
import { PNThemeCardTheme } from './themes'

type PNThemeCardRoutingProps = Omit<CardRouteProps, 'fontScale'> & {
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    cardNumber?: number
    theme: PNThemeCardTheme
}

export const PNThemeCardRegistration = createRegisterableComponentWithData(
    'pn-theme-card',
    ContentDataDefinitionLoader,
    (props: PNThemeCardRoutingProps, data, services) => {
        const product = useProduct()
        if (!data.loaded) {
            return null
        }

        if (data.loaded && data.result.publications.length === 0) {
            // Can happen if we are driven by a curation and publication(s) have been killed
            // or curation metadata which can be empty by choice
            return null
        }

        const results = data.loaded && data.result.publications[0]
        if (results && results.cardType !== 'publication') {
            return null
        }

        return (
            <ImpressionAvailable
                loading={!data.loaded}
                available={() => {
                    if (!data.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }

                    services.onEvent(
                        createCollectionAvailableEvent(
                            data.result.publications,
                            'PNThemeCard',
                            product,
                            services.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <StyledThemeCardWrapper>
                        <PNThemeCard
                            innerRef={ref}
                            item={
                                results
                                    ? { loaded: true, result: results }
                                    : { loaded: false }
                            }
                            fixedRatio={props.fixedRatio || '4:3'}
                            onEvent={services.onEvent}
                            cardNumber={props.cardNumber || 1}
                            theme={props.theme}
                        />
                    </StyledThemeCardWrapper>
                )}
            </ImpressionAvailable>
        )
    },
)
