import { ConfigurationContext } from '@news-mono/web-common'
import React from 'react'
import { brightcovePlayerWebId } from '../../../../content/Video/providers/PlayerProps'
import { PNArticleVideoProps } from './PNArticleVideo'

// TODO SWM-4689 these fall back IDs are masthead specific and should not be required
export const PNRssArticleVideo: React.FC<PNArticleVideoProps> = ({
    videoDetails,
}) => {
    const videoId = videoDetails.id
    const config = React.useContext(ConfigurationContext)

    return (
        <iframe
            width="560"
            height="315"
            src={`https://players.brightcove.net/${config.brightcoveAccountId}/${brightcovePlayerWebId}_default/index.html?videoId=${videoId}`}
            frameBorder="0"
        />
    )
}
