import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { duration } from '../../__styling/settings/easing'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint, calcRem } from '../../__styling/style-functions'
import { Phase } from './PNStickySiteHeader'
interface StyledPNStickyHeaderProps {
    disableSticky?: boolean
    phase: Phase
}

interface RedesignProps {
    redesignEnabled?: boolean
}

export const StickyHeaderHeightProp = '--stickyheader-hidden-height'

export const StyledPNStickyContainer = styled('div')<StyledPNStickyHeaderProps>(
    (props) => {
        const { phase, disableSticky } = props

        const isNotPhase0 = phase !== 'phase0'

        return {
            width: '100%',
            [StickyHeaderHeightProp]: 0,
            position: disableSticky
                ? undefined
                : isNotPhase0
                ? 'fixed'
                : 'sticky',
            top: isNotPhase0 ? '-55px' : 0,
            zIndex: zIndex.perthnow.stickySiteHeader,
            transform: `translateY(var(${StickyHeaderHeightProp}))`,
            [breakpoint('lg')]: {
                transition: `transform ${duration.fast} ease-out`,
                position: disableSticky ? undefined : 'sticky',
                top: 0,
            },
        }
    },
)
StyledPNStickyContainer.displayName = 'StyledPNStickyContainer'

export const StyledHeaderContainer = styled('div')({
    background: colors.white,
})
StyledHeaderContainer.displayName = 'StyledHeaderContainer'

export const StyledNavContainer = styled('div')<RedesignProps>(
    ({ redesignEnabled = 'false' }) => ({
        margin: '0 auto',
        maxWidth: redesignEnabled
            ? calcRem(metrics.perthnow.siteMetrics.mainContentWidthNGN)
            : calcRem(metrics.perthnow.siteMetrics.mainContentWidth),
    }),
)
StyledNavContainer.displayName = 'StyledNavContainer'
