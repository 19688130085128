import React from 'react'

export const IconHamburgerNGN = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M3 12H21M3 6H21M3 18H21"
            stroke="#252525"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconHamburgerNGN.displayName = 'IconHamburgerNGN'
