import React from 'react'
import { cx } from '@emotion/css'

export interface IconChevronNGNProps {
    className?: string
}

export const IconChevronNGN: React.FC<IconChevronNGNProps> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={cx(className, 'icon-chevron-down')}
    >
        <path
            d="M18 15L12 9L6 15"
            stroke="#252525"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconChevronNGN.displayName = 'IconChevronNGN'
