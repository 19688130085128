import React, { RefObject } from 'react'
import { PNStickySiteHeader } from '@news-mono/component-library'
import {
    BaseColumn,
    BaseGrid,
    BaseRow,
    NestedBaseColumn,
    NestedBaseGrid,
    NestedBaseRow,
    StyledBase,
} from '../BasePerthNow.styled'

export interface BaseProps {
    header: JSX.Element
    primaryNav: JSX.Element
    secondaryNav?: JSX.Element
    windowShade: JSX.Element
    content: JSX.Element
    footer: JSX.Element
    footerAd: JSX.Element
    disableStickyHeader?: boolean
    fullPageWidth?: boolean
    fullContentFill?: boolean
    headerRowRef: RefObject<HTMLDivElement>
    ngnRedesignLeaderboardAdRef: RefObject<HTMLDivElement>
    ngnRedesignHeaderLogoRowRef: RefObject<HTMLDivElement>
    redesignEnabled: boolean
    inApp: boolean
}

const Template: React.FC<BaseProps> = (props) => {
    const {
        redesignEnabled,
        ngnRedesignLeaderboardAdRef,
        ngnRedesignHeaderLogoRowRef,
    } = props

    return (
        <StyledBase redesignEnabled={redesignEnabled}>
            {/* Header */}
            <BaseGrid>
                <PNStickySiteHeader
                    headerRowRef={props.headerRowRef}
                    ngnRedesignLeaderboardAdRef={ngnRedesignLeaderboardAdRef}
                    ngnRedesignHeaderLogoRowRef={ngnRedesignHeaderLogoRowRef}
                    header={props.header}
                    navigation={props.primaryNav}
                    secondaryNav={props.secondaryNav}
                    disableSticky={props.disableStickyHeader}
                />
                {/* Window Shade */}
                {!redesignEnabled && (
                    <BaseRow>
                        <BaseColumn>
                            <NestedBaseGrid redesignEnabled={redesignEnabled}>
                                <NestedBaseRow>
                                    <NestedBaseColumn
                                        redesignEnabled={redesignEnabled}
                                    >
                                        {props.windowShade}
                                    </NestedBaseColumn>
                                </NestedBaseRow>
                            </NestedBaseGrid>
                        </BaseColumn>
                    </BaseRow>
                )}
                {/* Content */}
                {props.fullPageWidth ? (
                    <BaseRow>
                        <BaseColumn>{props.content}</BaseColumn>
                    </BaseRow>
                ) : (
                    <BaseRow>
                        <BaseColumn>
                            <NestedBaseGrid redesignEnabled={redesignEnabled}>
                                <NestedBaseRow>
                                    <NestedBaseColumn
                                        redesignEnabled={redesignEnabled}
                                        hasVerticalGutter={props.inApp}
                                    >
                                        {props.content}
                                    </NestedBaseColumn>
                                </NestedBaseRow>
                            </NestedBaseGrid>
                        </BaseColumn>
                    </BaseRow>
                )}
                {/* Footer Advertisment (only if not full content fill) */}
                {props.fullContentFill !== true && (
                    <BaseRow>
                        <BaseColumn>
                            <NestedBaseGrid redesignEnabled={redesignEnabled}>
                                <NestedBaseRow>
                                    <NestedBaseColumn
                                        redesignEnabled={redesignEnabled}
                                    >
                                        {props.footerAd}
                                    </NestedBaseColumn>
                                </NestedBaseRow>
                            </NestedBaseGrid>
                        </BaseColumn>
                    </BaseRow>
                )}
            </BaseGrid>

            {/* Footer */}
            <BaseGrid>
                <BaseRow>
                    <BaseColumn>{props.footer}</BaseColumn>
                </BaseRow>
            </BaseGrid>
        </StyledBase>
    )
}

export default Template
