import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { metricsPN } from '@news-mono/design-tokens'

export const pnArticleVideoClassName = 'pn-article-video'

export interface StyledPNArticleVideoProps {
    isHeroVideo?: boolean
    mediaMode?: string
}

const { spacing } = metricsPN

export const StyledPNArticleVideo = styled('figure')<StyledPNArticleVideoProps>(
    {
        flexGrow: 1,
        marginTop: 0,
        clear: 'both',
    },
    ({ isHeroVideo }) => ({
        marginTop: calcRem(spacing.L),
        pointerEvents: 'auto',
    }),
)

export const StyledPNArticleVideoContainer = styled(
    'div',
)<StyledPNArticleVideoProps>(({ theme, isHeroVideo, mediaMode }) => ({}))
